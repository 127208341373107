import React, { FC, useEffect, useRef, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { Logo } from "../logo/Logo";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { LoginWindow } from "../login/LoginWindow";
import { LoginForm } from "../../common/login/LoginForm";
import { useUser } from "../../../features/user/useUser";
import { Link, useLocation } from "react-router-dom";
import { UserTitle } from "./UserTitle";
import { ShowBalance } from "../balance/ShowBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import HeaderMenuItem from "./HeaderMenuItem";
import { HeaderDecorationBottom } from "./HeaderDecorationBottom";
import LanguageSelector from "../language_selector/LanguageSelector";
import { normalizePath } from "../../../features/common_funcs";
import { useLanguage } from "../../../features/localisation/useLanguage";
import SearchIcon from '@mui/icons-material/Search';
import { GameSearchWindow } from "../GameSearchWindow";
import {theme} from "../../../themes/salta_la_banca/salta_la_banca_theme";

interface HeaderProps {
    onHeightChange?: (height: number) => void;
}

export const Header: FC<HeaderProps> = ({ onHeightChange }) => {
    const location = useLocation()
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}, loginForm: loginLang}}} = useLanguage()
    const headerRef = useRef<HTMLDivElement>(null);
    const { xxSmallScreen, smallScreen, largeScreen } = useCommonMediaQueries()
    const [loginWindowOpen, setLoginWindowOpen] = useState(false);
    const { token, user, refreshUserBalance } = useUser()
    const [justMounted, setJustMounted] = useState(true)
    const currentPath = normalizePath(window.location.pathname);
    const [ searchWindowOpen, setSearchWindowOpen ] = useState(false)
    
    useEffect(() => {
        if (justMounted && user && token) {
            refreshUserBalance()
            setJustMounted(false)
        }
    }, [justMounted, refreshUserBalance, token, user])

    useEffect(() => {
        if (onHeightChange) {
            if (headerRef.current) {
                const height = headerRef.current.offsetHeight;
                onHeightChange(height);
            }
        }
    }, [onHeightChange]);

    return (
        <>
            <Stack ref={headerRef} sx={{backgroundColor: theme.palette.surface.main}}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={'10px'}
                    py={'5px'}
                >
                    <Link to={'/'}><Logo /></Link>

                    { !largeScreen && <Stack
                        direction={'row'}
                        component={'ul'}
                        sx={{
                            listStyle: 'none',
                            padding: 0,
                            margin: 0,
                            display: 'flex'
                        }}
                    >
                        <HeaderMenuItem to={'/'} text={menuLang.home} isActive={currentPath === ''} />
                        <HeaderMenuItem to={'/crash'} text={menuLang.crash} isActive={currentPath === '/crash'} />
                        <HeaderMenuItem to={'/slots'} text={menuLang.slots} isActive={currentPath === '/slots'} />
                        <HeaderMenuItem to={'/live_casino'} text={menuLang.casino} isActive={currentPath === '/live_casino'} />
                        <HeaderMenuItem to={'/sport'} text={menuLang.sport} isActive={currentPath === '/sport'} />
                        <HeaderMenuItem to={'/table_games'} text={menuLang.tableGames} isActive={currentPath === '/table_games'} />
                        {/*<HeaderMenuItem to={'/shooters'} text={menuLang.shooters} isActive={currentPath === '/shooters'} />*/}
                    </Stack>}

                    <Stack direction={'row'} alignItems={'center'} gap={'6px'}>
                        {!location.pathname.match(/\/(dashboard)/) && <IconButton onClick={() => setSearchWindowOpen(true)}>
                            <SearchIcon fontSize={'large'}/>
                        </IconButton>}
                        {(token && user) ? smallScreen ?
                            <Link to={'/dashboard'}><UserTitle username={user.user_name}/></Link> :
                            <Stack direction={'row'}>
                                <Stack
                                    justifyContent={'end'}
                                    px={'10px'}
                                    py={'2px'}
                                >
                                    <Typography
                                        px={'8px'}
                                        textTransform={'uppercase'}
                                        fontWeight={600}
                                        fontSize={'14px'}
                                        textAlign={'right'}
                                        sx={{ userSelect: 'none' }}
                                    >
                                        {user.user_name}
                                    </Typography>
                                    <Stack
                                        minWidth={'150px'}
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        gap={'5px'}
                                    >
                                        <ShowBalance balance={user.user_balance} currency={user.user_currency ?? '---'}/>
                                        {/*<ShowBalance balance={user.user_bonus_balance} currency={'Bonus'}/>*/}
                                    </Stack>
                                </Stack>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Link to={'/dashboard'}><IconButton>
                                        <AccountCircleIcon color={'primary'} sx={{ fontSize: '50px' }}/>
                                    </IconButton></Link>
                                </Box>
                            </Stack> : (smallScreen ?
                            <Button onClick={() => setLoginWindowOpen(true)} variant={'outlined'}>{loginLang.loginBTN}</Button> :
                            <Stack direction={'row'} gap={'10px'}>
                                <LoginForm/>
                            </Stack>)
                        }
                        {!smallScreen && <LanguageSelector/>}
                    </Stack>
                </Stack>

                {token && user  && smallScreen  && <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={'10px'}
                    py={'5px'}
                    gap={'5px'}
                >
                    <ShowBalance balance={user.user_balance} currency={user.user_currency ?? '---'}/>
                    {/*<ShowBalance balance={user.user_bonus_balance} currency={'Bonus'}/>*/}
                </Stack>}

                {!smallScreen && <HeaderDecorationBottom/>}
            </Stack>

            { searchWindowOpen && <GameSearchWindow onClose={() => setSearchWindowOpen(false)}/>}
            { (xxSmallScreen || smallScreen) && loginWindowOpen && <LoginWindow close={() => setLoginWindowOpen(false)}/> }
        </>

    );
};
