import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { DecoratedTitle } from "../decorated_title/DecoratedTitle";
import { TopGameHolder } from "./models/models";
import { TopGamesList } from "./TopGamesList";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

import TreasureChest from '../../../../assets/salta-la-banca/top_games/SaltaLaBancaChest.webp'
import TreasureChest2x from '../../../../assets/salta-la-banca/top_games/SaltaLaBancaChest@2x.webp'

import CornerLT from '../../../../assets/salta-la-banca/top_games/corner_LT.png'
import CornerLB from '../../../../assets/salta-la-banca/top_games/corner_LB.png'
import CornerRT from '../../../../assets/salta-la-banca/top_games/corner_RT.png'
import CornerRB from '../../../../assets/salta-la-banca/top_games/corner_RB.png'
import { useLanguage } from "../../../features/localisation/useLanguage";
// import { getProductStage } from "../../../features/common_funcs";
import {theme} from "../../../themes/salta_la_banca/salta_la_banca_theme";

export const TopGamesSection: FC = () => {
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const { largeScreen, xLargeScreen } = useCommonMediaQueries()

    const domain = window.location.hostname;
    console.log(domain);

    // const isStaging = getProductStage() === 'staging';

    return <Box>
        <Box position={'relative'} pt={'27px'}>
            <Box position={'absolute'} top={0} left={'50%'} sx={{ transform: 'translateX(-50%)' }}>
                <DecoratedTitle>{lang.topGames}</DecoratedTitle>
            </Box>

            <Stack
                direction={'row'}
                py={'36px'}
                px={largeScreen ? 0 : '2vw'}
                sx={largeScreen ? {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    backgroundImage: `#121316)`,
                    backgroundPosition: `center`,
                    backgroundSize: `auto`,
                    backgroundRepeat: 'no-repeat',
                } : {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    backgroundImage: `url(${CornerLT}), url(${CornerLB}), url(${CornerRT}), url(${CornerRB}), linear-gradient(#22242A, #121316)`,
                    backgroundPosition: `top left, bottom left, top right, bottom right, center`,
                    backgroundSize: `301px 22px, 301px 22px, 301px 22px, 301px 22px, auto`,
                    backgroundRepeat: 'no-repeat',
                }}
                borderRadius={'20px'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={'2vw'}
            >
                {!largeScreen && <Box
                    width={'20vw'}
                    height={'20vw'}
                    sx={{
                        backgroundImage: `url(${xLargeScreen ? TreasureChest : TreasureChest2x})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                />}
                <Box
                    width={largeScreen ? '100%' : '75vw'}
                >
                    <TopGamesList gamesList={topGamesListProduction}/>
                </Box>
            </Stack>
        </Box>


    </Box>
}

const topGamesListProduction: TopGameHolder[] = [
    { gamePic: '/cdn/game_img/sqr_mid/galaxsys.ninja_crash.jpg', gameID: 3854 },
    { gamePic: '/cdn/game_img/sqr_mid/DreamPlay.DualHearts.webp', gameID: 10751 },
    { gamePic: '/cdn/game_img/sqr_mid/bgaming.PlinkoXY.webp', gameID: 7908 },
    { gamePic: '/cdn/game_img/sqr_mid/Tada.ShanghaiBeauty.png', gameID: 10278 },
    { gamePic: '/cdn/game_img/sqr_mid/Mancala.MioandNekoRock.webp', gameID: 10446 },
    { gamePic: '/cdn/game_img/sqr_mid/amigo_FruitsAndCoins.jpg', gameID: 8126 },
    { gamePic: '/cdn/game_img/sqr_mid/ezugi.spanish_roulette_1.jpg', gameID: 3711 },
    { gamePic: '/cdn/game_img/sqr_mid/bgaming.LuckyOak.webp', gameID: 7923 },
    { gamePic: '/cdn/game_img/sqr_mid/Tada.LuckyJaguar.png', gameID: 10421 },
    { gamePic: '/cdn/game_img/sqr_mid/Mancala.GemBlitzBonanza.webp', gameID: 10447 },
    { gamePic: '/cdn/game_img/sqr_mid/amigo_BookOfZeus.jpg', gameID: 8049 },
    { gamePic: '/cdn/game_img/sqr_mid/pgsoft.songkran_splash.jpg', gameID: 3390 },
]